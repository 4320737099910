import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from "@/helpers/moment";
import countries from "i18n-iso-countries";
import messageComponent from "@/config/message";
import config from '@/config'
import XLSX from "xlsx";

import axios from "axios";

export const mixin = {

  methods: {
    getDefaultAvenantPayementStatus(projectAvenants) {
      let defaultAvenant = {};
      projectAvenants.forEach((element) => {
        if (element.isDefault) {
          defaultAvenant = element;
        }
      });
      return defaultAvenant.paymentStatus;
    },
    getDefaultQuittancePayementStatus(projectQuittances) {
      let defaultQuittance = {};
      projectQuittances.forEach((element) => {
        if (element.isDefault) {
          defaultQuittance = element;
        }
      });
      return defaultQuittance.paymentStatus;
    },
    getDefaultQuittance(projectQuittances) {
      let defaultQuittance = {};
      projectQuittances.forEach((element) => {
        if (element.isDefault) {
          defaultQuittance = element;
        }
      });
      return defaultQuittance;
    },
    getDefaultAvenant(projectAvenants) {
      let defaultAvenant = {};
      projectAvenants.forEach((element) => {
        if (element.isDefault) {
          defaultAvenant = element;
        }
      });
      return defaultAvenant;
    },
    getAllCountry() {
      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
      let countriesArray = [];
      if (
        Object.keys(countries.getNames("fr")).length ===
        Object.values(countries.getNames("fr")).length
      ) {
        Object.keys(countries.getNames("fr")).forEach((value, index) => {
          let tempJson = {};
          tempJson.id = Object.keys(countries.getNames("fr"))[index];
          tempJson.label = Object.values(countries.getNames("fr"))[index];
          countriesArray.push({ ...tempJson });
        });
      }
      return countriesArray;
    },
    formatDate(dateString) {
      if (dateString != undefined) {
        var p = dateString.split(/\D/g)
        return [p[2], p[1], p[0]].join("/")
      }
    },

    formatTimestampDate(dateString) {
      if (dateString != undefined) {
        var p = dateString.split(/\D/g)
        console.log(p)
        return [p[3], p[2], p[1]].join("-")
      }
    },

    filterJson(table, key) {
      let listSort = table;
      let res = listSort.sort((a, b) => {
        return b[key] > a[key] ? 1 : -1;
      });
      return res;
    },
    filterJsonTableByLevel(table, level1, level2, level3) {
      let listSort = [...table];
      if (!level3 == "") {
        let res = listSort.sort((a, b) => {
          return a[level1][level2][level3].toLowerCase() > b[level1][level2][level3].toLowerCase() ? 1 : -1;
        });
        return res;
      } else {
        if (!level2 == "") {
          let res = listSort.sort((a, b) => {
            return a[level1][level2].toLowerCase() > b[level1][level2].toLowerCase() ? 1 : -1;
          });
          return res;
        } else {
          let res = listSort.sort((a, b) => {
            return a[level1].toLowerCase() > b[level1].toLowerCase() ? 1 : -1;
          });
          return res;
        }
      }
    },


    exportToData(
      dataList,
      dataTitles,
      dataHeaderVal,
      fileName,
      autoWidth,
      bookType
    ) {
      import("@/vendor/Export2Excel").then(excel => {
        const list = dataList;
        const data = this.formatJson(dataHeaderVal, list);
        excel.export_json_to_excel({
          header: dataTitles,
          data,
          filename: fileName,
          autoWidth: autoWidth,
          bookType: bookType
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j];
        })
      );
    },
    /*exportToPdf(dataList, ColumnsFormated, fileName, title) {
      const doc = new jsPDF('l', 'cm', 'a4');
      doc.text(title, 2,2);
      doc.text("", 1,1);

      doc.autoTable({
            startY: 3,
            body: dataList,
            columns: ColumnsFormated
        });
        doc.save(`${fileName}.pdf`);
    },*/



    message(composant, type) {
      let msg = {};
      msg = { ...messageComponent[composant][type] }
      return msg;
    },


    exportToPdf(dataList, ColumnsFormated, fileName) {
      const doc = new jsPDF('l', 'cm', 'a4');
      doc.autoTable({
        body: dataList,
        columns: ColumnsFormated
      });
      doc.save(`${fileName}.pdf`);
    },

    lastLogin(lastLoginAt) {
      let title = "Dernière connexion";
      let text = "Néant";
      if (lastLoginAt) {
        text = moment(parseInt(lastLoginAt, 10)).format("DD/MM/YYYY [à] HH:mm");
      }
      return {
        title,
        text: "Le " + text
      };
    },
    userDateElement(data) {
      let { whoCreated, whoUpdated, createdAt, updatedAt } = data;
      let title = "Informations";
      let textWhoCreated = "APS Bénin";
      let textWhoUpdated = "APS Bénin";
      let textCreatedAt = "Néant";
      let textUpdatedAt = "Néant";

      if (createdAt) {
        textCreatedAt = moment(parseInt(createdAt, 10)).format(
          "DD/MM/YYYY [à] HH:mm"
        );
      }
      if (updatedAt) {
        textUpdatedAt = moment(parseInt(updatedAt, 10)).format(
          "DD/MM/YYYY [à] HH:mm"
        );
      }
      if (whoCreated) {
        textWhoCreated = `${whoCreated.person.firstname} ${whoCreated.person.lastname}`;
      }
      if (whoUpdated) {
        textWhoUpdated = `${whoUpdated.person.firstname} ${whoUpdated.person.lastname}`;
      }

      return {
        createdAt: `Par ${textWhoCreated}, le ${textCreatedAt}`,
        updatedAt: `Par ${textWhoUpdated}, le ${textUpdatedAt}`
      }
    },
    dateElement(data) {
      let { createdAt, updatedAt, birthday } = data;
      let title = "Informations";
      let textCreatedAt = "Néant";
      let textUpdatedAt = "Néant";
      let textBirthday = "Néant";

      if (createdAt) {
        textCreatedAt = moment(parseInt(createdAt, 10)).format(
          "DD/MM/YYYY [à] HH:mm"
        );
      }
      if (updatedAt) {
        textUpdatedAt = moment(parseInt(updatedAt, 10)).format(
          "DD/MM/YYYY [à] HH:mm"
        );
      }
      if (birthday) {
        textBirthday = moment(parseInt(birthday, 10)).format(
          "DD/MM/YYYY"
        );
      }

      let text = `Création : le ${textCreatedAt}  Modification : le ${textUpdatedAt}`;
      let textInfo = `Création : le ${textCreatedAt} `;
      let textUp = `Modification : le ${textUpdatedAt}`

      let createdAtInfo = ` ${textCreatedAt} `;
      let birthdayInfo = ` ${textBirthday} `;

      return {
        title,
        text,
        createdAtInfo,
        textUpdatedAt,
        textCreatedAt,
        birthdayInfo,
        textInfo,
        textUp
      };
    },
    getAge(birthday) {
      if (birthday == "" || undefined) {
        return 0
      } else {
        let age = moment().diff(birthday, 'years')
        return age;
      }

    },

    compareDate(dateToCompare) {
      let A = moment(dateToCompare);
      let B = moment(new Date());
      console.log("diffrence", A.diff(B, 'days'));

      return A.diff(B, 'days')//moment(new Date()).isAfter(dateToCompare)
    },

    calculDateInFutur(crrDateToConvert, addValue) {
      let convertDate = moment(crrDateToConvert).add(addValue, 'years').subtract(1, 'days').calendar();
      return convertDate;
    },

    aboutElement(data) {
      let { whoCreated, whoUpdated, createdAt, updatedAt } = data;
      let title = "Informations";
      let textWhoCreated = "APS Bénin";
      let textWhoUpdated = "APS Bénin";
      let textCreatedAt = "Néant";
      let textUpdatedAt = "Néant";

      if (createdAt) {
        textCreatedAt = moment(parseInt(createdAt, 10)).format(
          "DD/MM/YYYY [à] HH:mm"
        );
      }
      if (updatedAt) {
        textUpdatedAt = moment(parseInt(updatedAt, 10)).format(
          "DD/MM/YYYY [à] HH:mm"
        );
      }
      if (whoCreated) {
        try {
          textWhoCreated = `${whoCreated.person.firstname} ${whoCreated.person.lastname}`;

        } catch (error) {
          textWhoCreated = `Néant`;
        }
      }
      if (whoUpdated) {
        try {
          textWhoUpdated = `${whoUpdated.person.firstname} ${whoUpdated.person.lastname}`;

        } catch (error) {
          textWhoUpdated = `Néant`;
        }
      }

      let text = `Création : ${textWhoCreated}, le ${textCreatedAt} Modification : ${textWhoUpdated}, le ${textUpdatedAt}`;

      let createdAtInfo = ` ${textCreatedAt} `;

      return {
        title,
        text,
        createdAtInfo
      };
    },
    deviseConverter(price) {
      let rate = 1.045 // replace by society data
      let symbole = "€" // replace by society data
      let result = (parseInt(price, 10) * parseInt(rate, 10))

      return `${symbole} ${result}`
    },
    checkDiffDay(start, end) {
      let A = moment(new Date(start))
      let B = moment(new Date(end))

      return B.diff(A, 'days')
    },
    async getAmountAndCotisationAPF(fileName) {

      const promise1 = new Promise((resolve, reject) => {
        let taux = {
          Capital: [],
          Cotisation: []
        };

        var url = `${config.serverURL}/downloads/${fileName}`;
        var oReq = new XMLHttpRequest();

        oReq.open("GET", url, true);
        oReq.responseType = "arraybuffer";

        oReq.onload = function (e) {
          var arraybuffer = oReq.response;

          / convert data to binary string /
          var data = new Uint8Array(arraybuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");

          / Call XLSX /
          var workbook = XLSX.read(bstr, { type: "binary" });

          let sheetName = workbook.SheetNames[0];// recuperation des éléménts de la premiere feuille
          let worksheet = workbook.Sheets[sheetName];
          XLSX.utils.sheet_to_json(worksheet).forEach((el, idx) => {
            console.log(XLSX.utils.sheet_to_json(worksheet).length)
            taux.Capital.push(el.Capital);
            taux.Cotisation.push(el.Cotisation);
            if (idx == XLSX.utils.sheet_to_json(worksheet).length - 1) {
              resolve(taux);
            }
          });
        };

        oReq.send();
      });

      return promise1;
    },
    valideQMStandard(data) {
      let ageCheck = false
      let IMC_Check = false
      let isNoQuestionCheck = false
       
      ageCheck = (moment().diff(data.birthday, 'years') < 56)

      let x = []
      data.survey.map((item, i) => {
        if (i !== 0 && i !== 1 && i !== 12) {
          // Different de la 13eme question et NON pour une autre
          x.push(item.quickResponse)
        }
      })
      isNoQuestionCheck = x.every((el) => el === false)

      let tmp_IMC = Math.round(parseInt(data.survey[1].response.text.value) / Math.pow((parseInt(data.survey[0].response.text.value) / 100),2) )
      IMC_Check = tmp_IMC < 35 // Si l’IMC d'un assuré est de 0 à 34 
      
      return (
        ageCheck && 
        IMC_Check && 
        isNoQuestionCheck
      )
    },
  }
};
